import 'core-js/stable';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './Containers/App';
import i18n from './translations/i18n';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import { AbilityContext } from './Components/Can';
import ability from './Components/Can/ability';
import MessageListener from './Containers/MessageListener';

import './sass/base.scss';

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <AbilityContext.Provider value={ability}>
          <App />
          <MessageListener />
        </AbilityContext.Provider>
      </Provider>
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

registerServiceWorker();
